body {
  font-size: 0.875rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  border: 0;
  outline: 0;
  appearance: none;
}

table.table {
  background: var(--color-white);
  width: 100%;
  border-radius: var(--border-radius-table);
  padding: var(--card-padding);
  text-align: left;
}

table.table td,
th {
  height: 2.8rem;
  border-bottom: 1px solid var(--color);
  font-size: 1.1rem;
}

table.table tbody tr:last-child td {
  border: none;
}

.information-trait::after {
  content: "";
  display: block;
  width: 4rem;
  height: 1px;
  margin: 1rem 0;
  background: #2f2f2f;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.active-app {
  background-color: #bf0712 !important;
}

.btn-app-default {
  background-color: #bf0712 !important;
  color: white !important;
}

.color-app-default {
  color: #bf0712 !important;
}

.border-bottom-default {
  color: #bf0712 !important;
  border-bottom: 2px solid #bf0712;
}

/*
* Navbar
*/

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

#header-fixed {
  position: fixed;
  top: 0px;
  display: none;
  background-color: white;
}
