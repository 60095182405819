.scoll {
  overflow-x: auto;
}
.main-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  font-size: 1rem;
}

.main-pagination select {
  padding: 0.5rem;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.action {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: left;
}
